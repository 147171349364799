<template>
  <div>
    <list-candidate v-if="show" :show="show" @view="view" />
    <div v-if="!show">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn icon color="primary" @click="back()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <view-vacance :vacancy="vacancy" :apply_cand="true" v-show="!show" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import listCandidate from "./../components/listVacancyCandidate.vue";

import viewVacance from "./../components/VacancyDetailsCard.vue";
export default {
  components: { listCandidate, viewVacance },
  name: "CandidateVacancy",
  data: () => ({
    vacancy: [],
    show: true,
  }),

  computed: {},

  methods: {
    view(vacancy) {
      this.vacancy = vacancy;
      this.show = false;
    },
    back() {
      this.show = !this.show;
    },
  },
};
</script>
